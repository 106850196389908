import { Base } from '@wilson/base';
import { User } from '../accounts/User';

export interface Notification extends Base {
  title: string;
  content: string;
  portalUrl: string | null;
  mobileUrl: string | null;
  contentParams: NotificationContentParams;
  notificationCategory: NotificationCategory;
  userNotifications?: UserNotification[];
}

export interface UserNotification extends Base {
  isRead: boolean;
  userId: string;
  user?: User;
  notificationId: string;
  notification?: Notification;
}

export enum NotificationCategory {
  Absence = 'absence',
  Service = 'service',
  Shift = 'shift',
  Qualification = 'qualification',
  Info = 'info',
}

export interface NotificationContentParams {
  name?: string;
  name2?: string;
  userName?: string;
  delay?: string;
  count?: string;
  startDate?: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
  reason?: string;
  qualificationCategory?: string;
  qualificationName?: string;
}

export interface UserNotificationSearchParams {
  userId: string;
  isRead?: boolean;
}

export enum NotificationTitle {
  TITLE_STAFF_ABSENT_NO_EFFECT = 'notifications.titles.TITLE_STAFF_ABSENT_NO_EFFECT',
  TITLE_STAFF_ABSENT_VACATION_REQUEST = 'notifications.titles.TITLE_STAFF_ABSENT_VACATION_REQUEST',
  TITLE_STAFF_ABSENT_VACATION_WITHDRAW = 'notifications.titles.TITLE_STAFF_ABSENT_VACATION_WITHDRAW',
  TITLE_STAFF_ABSENT_VACATION_ACCEPT = 'notifications.titles.TITLE_STAFF_ABSENT_VACATION_ACCEPT',
  TITLE_STAFF_ABSENT_VACATION_DECLINE = 'notifications.titles.TITLE_STAFF_ABSENT_VACATION_DECLINE',
  TITLE_STAFF_ABSENT_OPEN_SHIFT = 'notifications.titles.TITLE_STAFF_ABSENT_OPEN_SHIFT',
  TITLE_STAFF_ABSENT_FROM_PORTAL = 'notifications.titles.TITLE_STAFF_ABSENT_FROM_PORTAL',
  TITLE_STAFF_VACATION_FROM_PORTAL = 'notifications.titles.TITLE_STAFF_VACATION_FROM_PORTAL',
  TITLE_REMOVE_SHIFT = 'notifications.titles.TITLE_REMOVE_SHIFT',
  TITLE_SERVICE_DELAY_NO_EFFECT = 'notifications.titles.TITLE_SERVICE_DELAY_NO_EFFECT',
  TITLE_SERVICE_DELAY_OPEN_SERVICE = 'notifications.titles.TITLE_SERVICE_DELAY_OPEN_SERVICE',
  TITLE_SERVICE_DELAY_SHIFT_ADJUST = 'notifications.titles.TITLE_SERVICE_DELAY_SHIFT_ADJUST',
  TITLE_GENERAL_REMINDER = 'notifications.titles.TITLE_GENERAL_REMINDER',
  TITLE_SHIFT_INVALID_DELAY = 'notifications.titles.TITLE_SHIFT_INVALID_DELAY',
  TITLE_SHIFT_ASSIGN = 'notifications.titles.TITLE_SHIFT_ASSIGN',
  TITLE_ACTIVITY_COMMENT = 'notifications.titles.TITLE_ACTIVITY_COMMENT',
  TITLE_SHIFT_REENTER_REPORTED_TIMES = 'notifications.titles.TITLE_SHIFT_REENTER_REPORTED_TIMES',
  TITLE_STAFF_STARTED_SHIFT = 'notifications.titles.TITLE_STAFF_STARTED_SHIFT',
  TITLE_SHIFT_MISSING_IS_TIMES = 'notifications.titles.TITLE_SHIFT_MISSING_IS_TIMES',
  TITLE_SHIFT_UNASSIGNED_REASSIGNED_OR_DELETED = 'notifications.titles.TITLE_SHIFT_UNASSIGNED_REASSIGNED_OR_DELETED',
  TITLE_SHIFT_RELEASED_AGAIN = 'notifications.titles.TITLE_SHIFT_RELEASED_AGAIN',
  TITLE_RUNNING_SHIFT_UPDATED = 'notifications.titles.TITLE_RUNNING_SHIFT_UPDATED',
}

export enum NotificationContent {
  MSG_STAFF_STARTED_SHIFT = 'notifications.messages.MSG_STAFF_STARTED_SHIFT',
  MSG_STAFF_ABSENT_NO_EFFECT = 'notifications.messages.MSG_STAFF_ABSENT_NO_EFFECT',
  MSG_STAFF_ABSENT_VACATION_REQUEST = 'notifications.messages.MSG_STAFF_ABSENT_VACATION_REQUEST',
  MSG_STAFF_ABSENT_VACATION_WITHDRAW = 'notifications.messages.MSG_STAFF_ABSENT_VACATION_WITHDRAW',
  MSG_STAFF_ABSENT_VACATION_ACCEPT = 'notifications.messages.MSG_STAFF_ABSENT_VACATION_ACCEPT',
  MSG_STAFF_ABSENT_VACATION_DECLINE = 'notifications.messages.MSG_STAFF_ABSENT_VACATION_DECLINE',
  MSG_STAFF_ABSENT_OPEN_SHIFT = 'notifications.messages.MSG_STAFF_ABSENT_OPEN_SHIFT',
  MSG_REMOVE_SHIFT = 'notifications.messages.MSG_REMOVE_SHIFT',
  MSG_STAFF_ABSENT_FROM_PORTAL = 'notifications.messages.MSG_STAFF_ABSENT_FROM_PORTAL',
  MSG_STAFF_VACATION_FROM_PORTAL = 'notifications.messages.MSG_STAFF_VACATION_FROM_PORTAL',
  MSG_SERVICE_DELAY_NO_EFFECT = 'notifications.messages.MSG_SERVICE_DELAY_NO_EFFECT',
  MSG_SERVICE_DELAY_OPEN_SERVICE = 'notifications.messages.MSG_SERVICE_DELAY_OPEN_SERVICE',
  MSG_SERVICE_DELAY_SHIFT_ADJUST = 'notifications.messages.MSG_SERVICE_DELAY_SHIFT_ADJUST',
  MSG_LIVESHIFT_LOGOUT_REMINDER = 'notifications.messages.MSG_LIVESHIFT_LOGOUT_REMINDER',
  MSG_LIVESHIFT_LOGOUT_STAFF = 'notifications.messages.MSG_LIVESHIFT_LOGOUT_STAFF',
  MSG_LIVESHIT_LOGOUT_DISPATCHER = 'notifications.messages.MSG_LIVESHIT_LOGOUT_DISPATCHER',
  MSG_DELAY_INVALID_SHIFT = 'notifications.messages.MSG_DELAY_INVALID_SHIFT',
  MSG_DELAY_INVALID_SHIFT_NO_USER = 'notifications.messages.MSG_DELAY_INVALID_SHIFT_NO_USER',
  MSG_SHIFT_ASSIGN_SINGLE = 'notifications.messages.MSG_SHIFT_ASSIGN_SINGLE',
  MSG_SHIFT_ASSIGN_MULTIPLE = 'notifications.messages.MSG_SHIFT_ASSIGN_MULTIPLE',
  MSG_LIVESHIFT_NEXTSHIFT_WARNING = 'notifications.messages.MSG_LIVESHIFT_NEXTSHIFT_WARNING',
  MSG_LIVESHIFT_NEXTSHIFT_PORTAL_WARNING = 'notifications.messages.MSG_LIVESHIFT_NEXTSHIFT_PORTAL_WARNING',
  MSG_ACTIVITY_COMMENT = 'notifications.messages.MSG_ACTIVITY_COMMENT',
  MSG_SHIFT_LOGIN_REMINDER_DISPATCHER = 'notifications.messages.MSG_SHIFT_LOGIN_REMINDER_DISPATCHER',
  MSG_SHIFT_LOGIN_REMINDER_STAFF = 'notifications.messages.MSG_SHIFT_LOGIN_REMINDER_STAFF',
  MSG_SHIFT_CHANGES = 'notifications.messages.MSG_SHIFT_CHANGES',
  MSG_SHIFT_REENTER_REPORTED_TIMES = 'notifications.messages.MSG_SHIFT_REENTER_REPORTED_TIMES',
  MSG_SHIFT_MISSING_IS_TIMES = 'notifications.messages.MSG_SHIFT_MISSING_IS_TIMES',
  MSG_SHIFT_UNASSIGNED_REASSIGNED_OR_DELETED = 'notifications.messages.MSG_SHIFT_UNASSIGNED_REASSIGNED_OR_DELETED',
  MSG_SHIFT_RELEASED_AGAIN = 'notifications.messages.MSG_SHIFT_RELEASED_AGAIN',
  MSG_RUNNING_SHIFT_UPDATED = 'notifications.messages.MSG_RUNNING_SHIFT_UPDATED',
}
