@let hasDeleteStayPermissions = RoleAction.Delete | ablePure :
RolePermissionSubject.Shift | async; @let hasUpdateStayPermissions =
RoleAction.Update | ablePure : RolePermissionSubject.Shift | async;
<nz-drawer
  [nzVisible]="true"
  [nzMask]="showMask"
  nzPlacement="right"
  [nzTitle]="'page.shift_timeline.hotel_stay.drawer.title' | translate"
  [nzFooter]="drawerFooter"
  [nzClosable]="false"
  (nzOnClose)="closeDrawer()"
>
  <ng-container *nzDrawerContent>
    <wilson-timeline-stays-drawer-form
      [isUserFieldReadOnly]="isUserFieldReadOnly"
      [prefillValues]="
        staysDrawerHelperService.preparePrefillEditStayObject(stayToEdit)
      "
      [sector]="sector"
      [showCancelledStatus]="true"
      (failedToFillForm)="failedToFillForm.emit()"
      (isInvalid)="isFormInvalidSignal.set($event)"
      (value)="formValueSignal.set($event)"
      (handleInvoice)="handleInvoice.emit($event)"
      (handleAttachments)="handleAttachments.emit($event)"
    ></wilson-timeline-stays-drawer-form>
  </ng-container>

  <ng-template #drawerFooter>
    <div class="drawer__footer">
      <button
        (click)="closeDrawer()"
        nz-button
        nzType="default"
        class="w-100"
        [disabled]="disableButtons"
      >
        {{ 'general.cancel' | translate }}
      </button>
      <nz-button-group class="w-100">
        <button
          [nzLoading]="isSaving"
          [disabled]="
            !hasUpdateStayPermissions || isFormInvalidSignal() || disableButtons
          "
          (click)="hasUpdateStayPermissions && emitConfirmEvent()"
          nz-button
          nz-tooltip
          [nzTooltipTitle]="
            !hasUpdateStayPermissions
              ? ('general.forbidden_action' | translate)
              : ''
          "
          nzType="primary"
          class="w-100"
          data-test-id="wilson-edit-stays-drawer-view:accept-button"
        >
          {{ 'general.accept' | translate }}
        </button>
        <button
          nz-button
          nz-dropdown
          [disabled]="isFormInvalidSignal() || disableButtons"
          nzType="primary"
          nzTrigger="click"
          [nzDropdownMenu]="action"
          data-test-id="wilson-edit-stays-drawer-view:drop-down-button"
        >
          <i class="fas fa-angle-down"></i>
        </button>
        <nz-dropdown-menu #action="nzDropdownMenu">
          <ul nz-menu>
            <li
              class="fw-normal"
              nz-menu-item
              nz-tooltip
              [nzTooltipTitle]="
                !hasUpdateStayPermissions
                  ? ('general.forbidden_action' | translate)
                  : ''
              "
              [nzDisabled]="disableButtons || !hasUpdateStayPermissions"
              (click)="
                hasUpdateStayPermissions && emitConfirmAndCreateReimbursement()
              "
              data-test-id="wilson-edit-stays-drawer-view:emit-and-confirm-reimbursement-button"
            >
              {{
                'page.shift_timeline.stay.save_and_create_reimbursement'
                  | translate
              }}
            </li>
            <li
              class="fw-normal"
              nz-menu-item
              nz-tooltip
              [nzTooltipTitle]="
                !hasUpdateStayPermissions
                  ? ('general.forbidden_action' | translate)
                  : ''
              "
              [nzDisabled]="disableButtons || !hasUpdateStayPermissions"
              (click)="hasUpdateStayPermissions && cancelStay()"
              data-test-id="wilson-edit-stays-drawer-view:cancel-stay-button"
            >
              {{ 'page.stays.cancel_stay' | translate }}
            </li>
            <li
              class="fw-normal"
              nz-menu-item
              nz-tooltip
              [nzTooltipTitle]="
                !hasUpdateStayPermissions
                  ? ('general.forbidden_action' | translate)
                  : ''
              "
              [nzDisabled]="disableButtons || !hasUpdateStayPermissions"
              (click)="hasUpdateStayPermissions && cancelStayAndGeneratePdf()"
              data-test-id="wilson-edit-stays-drawer-view:cancel-stay-and-notify-hotel-button"
            >
              {{ 'page.stays.cancel_stay_and_notify_hotel' | translate }}
            </li>
            <li
              class="fw-normal"
              nz-menu-item
              nz-tooltip
              [nzTooltipTitle]="
                !hasDeleteStayPermissions
                  ? ('general.forbidden_action' | translate)
                  : ''
              "
              [nzDisabled]="disableButtons || !hasDeleteStayPermissions"
              (click)="hasDeleteStayPermissions && performDelete()"
              data-test-id="wilson-edit-stays-drawer-view:delete-stay-button"
            >
              {{ 'page.stays.delete_stay' | translate }}
            </li>
          </ul>
        </nz-dropdown-menu>
      </nz-button-group>
    </div>
  </ng-template>
</nz-drawer>
