<button
  nz-button
  nz-type="primary"
  class="w-100 fw-normal"
  (click)="updateShiftState()"
  [disabled]="disabled"
  *ngIf="
    ('portal-reopen-shift-from-detail-page' | featureFlagPure | async) &&
    (RoleAction.Update
      | ablePure : RolePermissionSubject.WorkTimeManagement
      | async) &&
    (FeatureName.WorkTimeManagement | featurePure | async) &&
    shift?.state === ShiftState.Submitted
  "
>
  <span class="fas fa-level-down-alt me-1"></span>
  {{ 'page.work_time_management.for_processing' | translate }}
</button>
