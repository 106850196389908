import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityDeviationStatus } from '@wilson/interfaces';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'wilson-activity-time-tag',
  standalone: true,
  imports: [CommonModule, NzTagModule, TranslateModule],
  template: `
    <ng-container
      *ngIf="
        activityTimeStatus === ActivityDeviationStatus.Reported;
        else deviationTimeBlock
      "
    >
      <span class="d-block text-info">
        <nz-tag [nzColor]="'blue'">{{
          accurateTime
            ? (accurateTime | date : 'dd.MM.yy, HH:mm')
            : accurateTime
        }}</nz-tag>
      </span>
    </ng-container>
    <ng-template #deviationTimeBlock>
      <div *ngIf="!!activityTimeDifference && activityTimeDifference !== 0">
        <span *ngIf="activityTimeStatus === ActivityDeviationStatus.Deviated">
          <nz-tag [nzColor]="activityTimeDifference > 0 ? 'volcano' : 'green'">
            {{ accurateTime | date : 'HH:mm' }}
            ({{
              activityTimeDifference > 0
                ? '+' + activityTimeDifference
                : activityTimeDifference
            }})</nz-tag
          >
        </span>
      </div>
    </ng-template>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityTimeTagComponent {
  @Input() activityTimeDifference: number | null | undefined;
  @Input() activityTimeStatus!: ActivityDeviationStatus;
  @Input() accurateTime!: string | null;
  ActivityDeviationStatus = ActivityDeviationStatus;
}
