<form [formGroup]="form">
  <div class="row">
    <div class="col-6 pe-0">
      <div class="form-text">{{ 'activity.split_location' | translate }}*</div>
      <wilson-location-selects
        [sector]="sector"
        formControlName="location"
      ></wilson-location-selects>
    </div>
    <div class="col-6">
      <div class="form-text">{{ 'activity.split_time' | translate }}*</div>
      <nz-input-group nzCompact class="w-100 d-flex">
        <nz-date-picker
          class="w-55"
          [nzFormat]="'P'"
          formControlName="date"
          [nzStatus]="
            form.controls['date'].touched && form.controls['date'].invalid
              ? 'error'
              : ''
          "
        ></nz-date-picker>
        <nz-time-picker
          class="w-45"
          [nzFormat]="'HH:mm'"
          formControlName="time"
          [nzStatus]="
            form.controls['time'].touched && form.controls['time'].invalid
              ? 'error'
              : ''
          "
        ></nz-time-picker>
      </nz-input-group>
    </div>
  </div>
  <div class="my-3">
    <label
      nz-checkbox
      formControlName="insertBreak"
      (ngModelChange)="setInsertBreak()"
    >
      {{ 'activity.insert_break' | translate }}
    </label>
  </div>
  <div *ngIf="form.controls['insertBreak'].value">
    <nz-divider class="my-3"></nz-divider>
    <div class="row">
      <div class="col-4 pe-0">
        <div class="form-text">
          {{ 'page.service.activity_type' | translate }}*
        </div>
        <nz-form-item class="mb-0">
          <nz-form-control
            [nzValidateStatus]="
              form?.controls['activityCategory'].touched &&
              !form?.controls['activityCategory'].value
                ? 'error'
                : 'success'
            "
          >
            <nz-select
              formControlName="activityCategory"
              [nzPlaceHolder]="'---'"
              [nzMaxTagCount]="1"
              nzShowSearch
              nzAllowClear
              [nzShowArrow]="true"
              [nzDropdownStyle]="nzMinWidthConfig"
            >
              <nz-option
                *ngFor="let category of activityCategories"
                [nzValue]="category"
                [nzLabel]="'activity_category.' + category.name | translate"
              >
              </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div class="col-5 pe-0">
        <div class="form-text">
          {{ 'activity.split_destination' | translate }}*
        </div>
        <wilson-location-selects
          [sector]="sector.name"
          formControlName="breakLocation"
        ></wilson-location-selects>
      </div>
      <div class="col-3">
        <div class="form-text">
          {{ 'page.absences.lbl_duration' | translate }}*
        </div>
        <nz-time-picker
          [nzFormat]="'HH:mm'"
          formControlName="breakTime"
          [nzStatus]="
            form.controls['breakTime'].touched &&
            form.controls['breakTime'].invalid
              ? 'error'
              : ''
          "
        ></nz-time-picker>
      </div>
    </div>
  </div>
  <p
    class="text-danger mt-3 mb-0"
    *ngIf="
      (form.controls['date'].touched && form.controls['date'].invalid) ||
      (form.controls['time'].touched && form.controls['time'].invalid) ||
      (form.controls['breakTime']?.touched &&
        form.controls['breakTime']?.invalid)
    "
  >
    {{ 'activity.invalid_split_datetime' | translate }}
  </p>
</form>
<nz-divider class="my-3"></nz-divider>
<div class="d-flex justify-content-end">
  <button
    nz-button
    class="text-dark p-1 px-4 me-2 fw-normal"
    (click)="dismiss()"
  >
    {{ 'general.cancel' | translate }}
  </button>
  <button
    nz-button
    nzType="primary"
    class="text-dark p-1 px-4 fw-normal"
    [disabled]="form.invalid"
    (click)="splitActivity()"
  >
    {{ 'activity.confirm_split' | translate }}
  </button>
</div>
