import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { Activity, ShiftCategoriesIdsMap } from '@wilson/interfaces';
import { Observable } from 'rxjs';

interface SuccessResponseWithError {
  code: 'INVALID_PROMPT';
  message: string;
}

export interface ShiftResponseDto {
  name: string;
  startDate: string;
  shiftCategoryId: `${ShiftCategoriesIdsMap}`;
  suggestedShiftCategoryIds: `${ShiftCategoriesIdsMap}`[];
  activities: Activity[];
}

export interface CreateShiftResponseDto {
  shift: ShiftResponseDto;
  warnings: [];
  errors?: SuccessResponseWithError[];
  inputLanguage: string;
}

@Injectable({
  providedIn: 'root',
})
export class ShiftGenerationGatewayService {
  protected readonly path = 'shift-generations';

  constructor(
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
    protected readonly http: HttpClient,
  ) {}

  createShift(payload: {
    prompt?: string;
    base64?: string;
  }): Observable<CreateShiftResponseDto> {
    return this.http.post<CreateShiftResponseDto>(
      `${this.config.host}/${this.path}`,
      payload,
    );
  }
}
