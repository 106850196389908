<nz-collapse [nzBordered]="false" nzGhost>
  <nz-collapse-panel
    [nzHeader]="'page.shifts.quick_check' | translate"
    [nzActive]="isExpandedOnOpen"
    class="collapse-panel"
  >
    <wilson-shift-quick-check
      *ngIf="shiftValidationDetails"
      [shiftValidationDetails]="shiftValidationDetails"
      [isUserAssigned]="!!userId"
    ></wilson-shift-quick-check>
  </nz-collapse-panel>
</nz-collapse>
