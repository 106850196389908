<div class="card">
  <div class="card-body">
    <h5 class="card-title text-dark">
      <i class="fas fa-exclamation-triangle text-secondary me-2"></i>
      {{ 'page.shifts.conflict' | translate }}
    </h5>
    <hr />
    <div class="card-text text-dark">
      <p>{{ 'page.shifts.conflict_info_title' | translate }}</p>
      <p>
        <span class="d-block" *ngFor="let activity of serviceActivities">
          <b>
            {{ activity | translateActivityCategory }} {{ activity.name }},
          </b>
          {{
            canEditServiceActivities
              ? activity.startDatetime
              : (activity.startDatetime | date : 'dd.MM.yy, HH:mm')
          }}
          {{ 'general.hour' | translate }} -
          {{
            canEditServiceActivities
              ? activity.endDatetime
              : (activity.endDatetime | date : 'dd.MM.yy, HH:mm')
          }}
          {{ 'general.hour' | translate }}
        </span>
      </p>
      <p>{{ 'page.shifts.conflict_info_message' | translate }}</p>
    </div>
    <hr />
    <div class="d-flex justify-content-end mt-4">
      <button
        type="button"
        class="btn btn-dark p-1 me-2 fw-normal"
        (click)="decline()"
      >
        {{ 'general.cancel' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-primary p-1 fw-normal"
        (click)="accept()"
      >
        {{ 'general.save' | translate }}
      </button>
    </div>
  </div>
</div>
