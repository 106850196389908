import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import * as Sentry from '@sentry/capacitor';
import { WorkTimeUtilizationsGatewayService } from '@wilson/api/gateway';
import { AuthState } from '@wilson/auth/core';
import { UserKpisInMinutes, WorktimeUtilizationDTO } from '@wilson/interfaces';
import { ShiftTimelinePreferredSettingsState } from '@wilson/preferred-settings/state';
import {
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { cloneDeep } from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, finalize, map, Subject, switchMap, takeUntil } from 'rxjs';
import { TimelineKpisStateModel } from '../interfaces';
import { ShiftTimelineDataState } from '../shift-timeline-data/shift-timeline-data.state';
import { defaultTimelineKpisState } from '../shift-timeline-default-data';
import {
  FetchKpisForAllFilteredUsers,
  SubscribeToKpiRequests,
  UnsubscribeFromKpiRequests,
  UpdateKpisOfUsers,
} from './timeline-kpis.actions';

const DATABASE_DATE_TIME_FORMAT = "yyyy-MM-dd'T'00:00:00.000'Z'";

@State({
  name: 'timelineKpisState',
  defaults: defaultTimelineKpisState,
})
@Injectable()
export class TimelineKpisState {
  private weekKpiFetchRequests$ = new Subject<{
    userIds: string[];
    timeFrame: { startDatetime: string; endDatetime: string };
    ctx: StateContext<TimelineKpisStateModel>;
  }>();
  private monthKpiFetchRequests$ = new Subject<{
    userIds: string[];
    timeFrame: { startDatetime: string; endDatetime: string };
    ctx: StateContext<TimelineKpisStateModel>;
  }>();
  private weekKpiUpdateRequests$ = new Subject<{
    userIds: string[];
    timeFrame: { startDatetime: string; endDatetime: string };
    ctx: StateContext<TimelineKpisStateModel>;
  }>();
  private monthKpiUpdateRequests$ = new Subject<{
    userIds: string[];
    timeFrame: { startDatetime: string; endDatetime: string };
    ctx: StateContext<TimelineKpisStateModel>;
  }>();

  private destroy$ = new Subject<void>();

  constructor(
    private readonly store: Store,
    private readonly workTimeUtilizationsGatewayService: WorkTimeUtilizationsGatewayService,
    private readonly nzMessageService: NzMessageService,
    private readonly translateService: TranslateService,
  ) {}

  @Selector()
  static userKpisForWeek(
    state: TimelineKpisStateModel,
  ): WorktimeUtilizationDTO {
    return state.userKpisForWeek;
  }

  @Selector()
  static userKpisForMonth(
    state: TimelineKpisStateModel,
  ): WorktimeUtilizationDTO {
    return state.userKpisForMonth;
  }

  @Selector()
  static isLoadingWeekKpis(state: TimelineKpisStateModel): boolean {
    return state.isLoadingWeekKpis;
  }

  @Selector()
  static isLoadingMonthKpis(state: TimelineKpisStateModel): boolean {
    return state.isLoadingMonthKpis;
  }

  @Selector()
  static loadingKpiUserIds(state: TimelineKpisStateModel): string[] {
    return state.loadingKpiUserIds;
  }

  static userKpisForWeekByUserId(
    userId: string,
  ): (userKpisForWeek: WorktimeUtilizationDTO) => UserKpisInMinutes {
    return createSelector(
      [TimelineKpisState.userKpisForWeek],
      (userKpisForWeek: WorktimeUtilizationDTO) => {
        return userKpisForWeek[userId];
      },
    );
  }

  static userKpisForMonthByUserId(
    userId: string,
  ): (userKpisForMonth: WorktimeUtilizationDTO) => UserKpisInMinutes {
    return createSelector(
      [TimelineKpisState.userKpisForMonth],
      (userKpisForMonth: WorktimeUtilizationDTO) => {
        return userKpisForMonth[userId];
      },
    );
  }

  @Action(SubscribeToKpiRequests)
  subscribeToKpiRequests(): void {
    this.handleWeekKpiFetchRequests();
    this.handleMonthKpiFetchRequests();
    this.handleWeekKpiUpdateRequests();
    this.handleMonthKpiUpdateRequests();
  }

  @Action(UnsubscribeFromKpiRequests)
  unsubscribeFromKpiRequests(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @Action(FetchKpisForAllFilteredUsers)
  fetchKpisForAllFilteredUsers(
    ctx: StateContext<TimelineKpisStateModel>,
    action: FetchKpisForAllFilteredUsers,
  ): void {
    const userIds = action.userIds;
    const timeFrameForWeek = this.getTimeFrameForWeek();
    const timeFrameForMonth = this.getTimeFrameForMonth();

    if (timeFrameForWeek) {
      ctx.patchState({ isLoadingWeekKpis: action.showLoading });
      this.weekKpiFetchRequests$.next({
        userIds,
        timeFrame: timeFrameForWeek,
        ctx,
      });
    } else {
      console.warn('Warning: Cannot fetch KPIs for week');
      ctx.patchState({
        userKpisForWeek: defaultTimelineKpisState.userKpisForWeek,
        isLoadingWeekKpis: false,
      });
    }

    if (timeFrameForMonth) {
      ctx.patchState({ isLoadingMonthKpis: action.showLoading });
      this.monthKpiFetchRequests$.next({
        userIds,
        timeFrame: timeFrameForMonth,
        ctx,
      });
    } else {
      console.warn('Warning: Cannot fetch KPIs for month');
      ctx.patchState({
        userKpisForMonth: defaultTimelineKpisState.userKpisForMonth,
        isLoadingMonthKpis: false,
      });
    }
  }

  @Action(UpdateKpisOfUsers)
  updateKpisOfUsers(
    ctx: StateContext<TimelineKpisStateModel>,
    action: UpdateKpisOfUsers,
  ): void {
    const userIds = action.userIds;
    const timeFrameForWeek = this.getTimeFrameForWeek();
    const timeFrameForMonth = this.getTimeFrameForMonth();

    if (timeFrameForWeek) {
      ctx.patchState({
        loadingKpiUserIds: userIds,
      });
      this.weekKpiUpdateRequests$.next({
        userIds,
        timeFrame: timeFrameForWeek,
        ctx,
      });
    }

    if (timeFrameForMonth) {
      ctx.patchState({
        loadingKpiUserIds: userIds,
      });
      this.monthKpiUpdateRequests$.next({
        userIds,
        timeFrame: timeFrameForMonth,
        ctx,
      });
    }
  }

  private handleWeekKpiFetchRequests(): void {
    this.weekKpiFetchRequests$
      .pipe(
        switchMap(({ userIds, timeFrame, ctx }) =>
          this.workTimeUtilizationsGatewayService
            .getWorkTimeUtilizations(userIds, {
              startDatetime: timeFrame.startDatetime,
              endDatetime: timeFrame.endDatetime,
            })
            .pipe(
              map((workTimeUtilizations: WorktimeUtilizationDTO) => {
                ctx.patchState({
                  userKpisForWeek: workTimeUtilizations,
                  isLoadingWeekKpis: false,
                });
              }),
              catchError((error) => {
                this.nzMessageService.error(
                  this.translateService.instant('general.error'),
                );
                ctx.patchState({ isLoadingWeekKpis: false });
                Sentry.captureException(error);
                return [];
              }),
              finalize(() => {
                ctx.patchState({
                  isLoadingWeekKpis: false,
                });
              }),
            ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private handleMonthKpiFetchRequests(): void {
    this.monthKpiFetchRequests$
      .pipe(
        switchMap(({ userIds, timeFrame, ctx }) =>
          this.workTimeUtilizationsGatewayService
            .getWorkTimeUtilizations(userIds, {
              startDatetime: timeFrame.startDatetime,
              endDatetime: timeFrame.endDatetime,
            })
            .pipe(
              map((workTimeUtilizations: WorktimeUtilizationDTO) => {
                ctx.patchState({
                  userKpisForMonth: workTimeUtilizations,
                  isLoadingMonthKpis: false,
                });
              }),
              catchError((error) => {
                this.nzMessageService.error(
                  this.translateService.instant('general.error'),
                );
                ctx.patchState({ isLoadingMonthKpis: false });
                Sentry.captureException(error);
                return [];
              }),
              finalize(() => {
                ctx.patchState({
                  isLoadingWeekKpis: false,
                });
              }),
            ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private handleWeekKpiUpdateRequests(): void {
    this.weekKpiUpdateRequests$
      .pipe(
        switchMap(({ userIds, timeFrame, ctx }) =>
          this.workTimeUtilizationsGatewayService
            .getWorkTimeUtilizations(userIds, {
              startDatetime: timeFrame.startDatetime,
              endDatetime: timeFrame.endDatetime,
            })
            .pipe(
              map((workTimeUtilizations: WorktimeUtilizationDTO) => {
                const clonedWorkTimeUtilizations = cloneDeep(
                  ctx.getState().userKpisForWeek,
                );
                for (const [userId, workTimeUtilization] of Object.entries(
                  workTimeUtilizations,
                )) {
                  clonedWorkTimeUtilizations[userId] = workTimeUtilization;
                }
                ctx.patchState({
                  userKpisForWeek: clonedWorkTimeUtilizations,
                  loadingKpiUserIds: [],
                });
              }),
              catchError(() => {
                this.nzMessageService.error(
                  this.translateService.instant('general.error'),
                );
                ctx.patchState({
                  loadingKpiUserIds: [],
                });
                return [];
              }),
            ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private handleMonthKpiUpdateRequests(): void {
    this.monthKpiUpdateRequests$
      .pipe(
        switchMap(({ userIds, timeFrame, ctx }) =>
          this.workTimeUtilizationsGatewayService
            .getWorkTimeUtilizations(userIds, {
              startDatetime: timeFrame.startDatetime,
              endDatetime: timeFrame.endDatetime,
            })
            .pipe(
              map((workTimeUtilizations: WorktimeUtilizationDTO) => {
                const clonedWorkTimeUtilizations = cloneDeep(
                  ctx.getState().userKpisForMonth,
                );
                for (const [userId, workTimeUtilization] of Object.entries(
                  workTimeUtilizations,
                )) {
                  clonedWorkTimeUtilizations[userId] = workTimeUtilization;
                }
                ctx.patchState({
                  userKpisForMonth: clonedWorkTimeUtilizations,
                  loadingKpiUserIds: [],
                });
              }),
              catchError(() => {
                this.nzMessageService.error(
                  this.translateService.instant('general.error'),
                );
                ctx.patchState({
                  loadingKpiUserIds: [],
                });
                return [];
              }),
            ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private getTimeFrameForWeek(): {
    startDatetime: string;
    endDatetime: string;
  } | null {
    const userId = this.store.selectSnapshot(AuthState.userId);
    if (userId) {
      const isWorkTimeWeekKpiVisible = this.store.selectSnapshot(
        ShiftTimelinePreferredSettingsState.showWorkTimeWeekKpi(userId),
      );
      const isPlannedTimeWeekKpiVisible = this.store.selectSnapshot(
        ShiftTimelinePreferredSettingsState.showPlannedTimeWeekKpi(userId),
      );
      const isOvertimeWeekKpiVisible = this.store.selectSnapshot(
        ShiftTimelinePreferredSettingsState.showOvertimeWeekKpi(userId),
      );
      if (
        isWorkTimeWeekKpiVisible ||
        isPlannedTimeWeekKpiVisible ||
        isOvertimeWeekKpiVisible
      ) {
        const timeframe = this.store.selectSnapshot(
          ShiftTimelineDataState.timeframe,
        );
        const weekStart = startOfWeek(timeframe.start, {
          weekStartsOn: 1,
        });
        const weekEnd = endOfWeek(timeframe.start, {
          weekStartsOn: 1,
        });
        return {
          startDatetime: format(weekStart, DATABASE_DATE_TIME_FORMAT),
          endDatetime: format(weekEnd, DATABASE_DATE_TIME_FORMAT),
        };
      }
    }
    return null;
  }

  private getTimeFrameForMonth(): {
    startDatetime: string;
    endDatetime: string;
  } | null {
    const userId = this.store.selectSnapshot(AuthState.userId);
    if (userId) {
      const isWorkTimeMonthKpiVisible = this.store.selectSnapshot(
        ShiftTimelinePreferredSettingsState.showWorkTimeMonthKpi(userId),
      );
      const isPlannedTimeMonthKpiVisible = this.store.selectSnapshot(
        ShiftTimelinePreferredSettingsState.showPlannedTimeMonthKpi(userId),
      );
      const isOvertimeMonthKpiVisible = this.store.selectSnapshot(
        ShiftTimelinePreferredSettingsState.showOvertimeMonthKpi(userId),
      );
      if (
        isWorkTimeMonthKpiVisible ||
        isPlannedTimeMonthKpiVisible ||
        isOvertimeMonthKpiVisible
      ) {
        const timeframe = this.store.selectSnapshot(
          ShiftTimelineDataState.timeframe,
        );
        const monthStart = startOfMonth(timeframe.start);
        const monthEnd = endOfMonth(timeframe.start);
        return {
          startDatetime: format(monthStart, DATABASE_DATE_TIME_FORMAT),
          endDatetime: format(monthEnd, DATABASE_DATE_TIME_FORMAT),
        };
      }
    }
    return null;
  }
}
