import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Agreement } from '@wilson/clients/interfaces';
import { Activity, ResolvedActivity } from '@wilson/interfaces';
import { BucketType } from '@wilson/share/interfaces';
import { ShareRequestStateHelperService } from '@wilson/share/state';

enum TOOLTIP_TEXT {
  ACTIVITY_ALREADY_IN_SHIFT = 'wilson.share.activity_already_in_shift',
  CANNOT_ADD_TO_BUCKET = 'wilson.share.cannot_add_to_bucket',
}

@Component({
  selector: 'wilson-share-activity',
  templateUrl: './share-activity.component.html',
  styleUrls: ['./share-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareActivityComponent {
  @Input() activity: ResolvedActivity;
  @Input() startDateTimeOverlap: string;
  @Input() endDateTimeOverlap: string;
  @Input() isDisabled = false;
  @Input() hasUpdatePermissions = false;
  @Output() shareStatusChanged = new EventEmitter<void>();

  get tooltipText(): TOOLTIP_TEXT | null {
    return this.activity?.shiftId
      ? TOOLTIP_TEXT.ACTIVITY_ALREADY_IN_SHIFT
      : this.activity?.jobId
      ? TOOLTIP_TEXT.CANNOT_ADD_TO_BUCKET
      : null;
  }

  get isIconDisabled(): string | boolean | Agreement {
    return (
      this.activity?.jobId ||
      this.activity?.shiftId ||
      this.activity.agreement ||
      this.isDisabled
    );
  }

  constructor(private shareService: ShareRequestStateHelperService) {}

  async addActivityToBucket(activity: Activity): Promise<void> {
    const added = await this.shareService.verifyWithUserAndAddItemsToBucket(
      this.activity.serviceId as string,
      [activity],
      BucketType.Service,
    );
    if (added) {
      this.shareStatusChanged.emit();
    }
  }

  removeActivityFromBucket(id: string): void {
    this.shareService.removeItemFromBucket(
      this.activity.serviceId as string,
      id,
    );
    this.shareStatusChanged.emit();
  }
}
