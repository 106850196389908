import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  RoleAction,
  RolePermissionSubject,
  Sector,
  Stay,
  StayStatus,
  TimelineStayManagementDrawerFormValue,
} from '@wilson/interfaces';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { StaysDrawerHelperService } from '../services/stays-drawer-helper.service';
import { StaysDrawerFormComponent } from '../stays-drawer-form/stays-drawer-form.component';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { StaysAttachmentsHelperService } from '../services/stays-attachments-helper.service';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AuthorizationModule } from '@wilson/authorization';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'wilson-edit-stays-drawer-view',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NzDrawerModule,
    NzButtonModule,
    StaysDrawerFormComponent,
    NzDropDownModule,
    AuthorizationModule,
    NzToolTipModule,
  ],
  templateUrl: './edit-stays-drawer-view.component.html',
  styleUrl: './edit-stays-drawer-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditStaysDrawerViewComponent implements OnInit {
  @Input({
    required: true,
  })
  sector!: Sector | null | undefined;

  @Input()
  showMask = false;

  @Input()
  disableButtons = false;

  @Input()
  isSaving = false;

  @Input({ required: true })
  stayToEdit!: Stay | null;

  @Input()
  isUserFieldReadOnly!: boolean;

  @Output()
  failedToFillForm = new EventEmitter();

  @Output()
  confirm = new EventEmitter<Partial<TimelineStayManagementDrawerFormValue>>();

  @Output()
  confirmAndCreateReimbursement = new EventEmitter<
    Partial<TimelineStayManagementDrawerFormValue>
  >();

  @Output()
  cancelStayAndCreatePdf = new EventEmitter<
    Partial<TimelineStayManagementDrawerFormValue>
  >();

  @Output()
  cancel = new EventEmitter<void>();

  @Output()
  delete = new EventEmitter<void>();

  @Output()
  handleInvoice = new EventEmitter<NzUploadFile[]>();

  @Output()
  handleAttachments = new EventEmitter<NzUploadFile[]>();

  protected isFormInvalidSignal = signal(false);
  protected formValueSignal = signal<
    Partial<TimelineStayManagementDrawerFormValue>
  >({});

  protected RoleAction = RoleAction;
  protected RolePermissionSubject = RolePermissionSubject;
  constructor(
    protected readonly staysDrawerHelperService: StaysDrawerHelperService,
    protected readonly staysAttachmentsHelperService: StaysAttachmentsHelperService,
    private readonly translate: TranslateService,
    private readonly modalService: NzModalService,
  ) {}

  ngOnInit(): void {
    this.staysAttachmentsHelperService.stayAttachments =
      this.stayToEdit?.attachments || [];
  }

  closeDrawer(): void {
    this.cancel.emit();
  }

  emitConfirmEvent(): void {
    this.confirm.emit(this.formValueSignal());
  }

  emitConfirmAndCreateReimbursement(): void {
    this.confirmAndCreateReimbursement.emit(this.formValueSignal());
  }

  cancelStay(): void {
    this.formValueSignal.set({
      ...this.formValueSignal(),
      stayStatus: StayStatus.Cancelled,
    });
    this.confirm.emit(this.formValueSignal());
  }

  cancelStayAndGeneratePdf(): void {
    this.formValueSignal.set({
      ...this.formValueSignal(),
      stayStatus: StayStatus.Cancelled,
    });
    this.cancelStayAndCreatePdf.emit(this.formValueSignal());
  }

  protected async performDelete(): Promise<void> {
    if (this.stayToEdit?.status === StayStatus.Open) {
      this.delete.emit();
    } else {
      this.modalService.confirm({
        nzTitle: this.translate.instant('page.stays.delete_stay'),
        nzContent: this.translate.instant('page.stays.delete_stay.description'),
        nzOkText: this.translate.instant('general.yes'),
        nzOkType: 'primary',
        nzOkDanger: true,
        nzCancelText: this.translate.instant('general.no'),
        nzOnOk: async () => {
          this.delete.emit();
        },
      });
    }
  }
}
