import { User } from './User';

export interface UserOvertime {
  user: User;
  overtimes: Overtime[];
}

export enum RangeTypeValues {
  CurrentMonth = 'current_month',
  PreviousTwoMonths = 'previous_two_months',
  PreviousThreeMonths = 'previous_three_months',
  Yearly = 'yearly',
}

export interface UserOvertimeMap {
  user: User;
  overtimes: Map<RangeTypeValues, Overtime>;
}

export interface Overtime {
  userId: string;
  timeRange: Interval;
  overtimeInMinutes: number;
  overtimeCompensationInMinutes: number;
  overtTimeByPayrollCategoryNames: Record<PayrollCategoryNames, number>;
}

export enum PayrollCategoryNames {
  LeisureTimeAccount = 'leisure-time-account',
}

/** @deprecated use `Interval` from `"date-fns"` instead. */
export interface IntervalDate {
  start: Date | string;
  end: Date | string;
}
