<div #tooltip class="tooltip-container">
  <div class="fw-normal text-primary header">
    <i class="fas fa-info-circle me-2"></i> {{ header }}
  </div>
  <div
    *ngIf="!isShiftScoreCard && !isMultiDetail; else tooltipLists"
    id="item-details"
    [innerHTML]="details"
  ></div>
  <ng-template #tooltipLists>
    <div *ngIf="isShiftScoreCard">
      <div
        class="d-flex"
        id="shift-ccore-card"
        *ngFor="let item of shiftScoreCard; let i = index"
      >
        <div class="me-3">
          <span class="dot" [ngClass]="item.color"></span>
        </div>
        {{ item.name | translate }}
      </div>
    </div>
    <div *ngIf="isMultiDetail">
      <div
        class="d-flex"
        *ngFor="let item of multiDetails.slice(0, 5); let i = index"
      >
        <div class="me-3">
          <span class="dot"></span>
        </div>
        {{ item }}
      </div>
      <div class="d-flex" *ngIf="multiDetails.length > 5">
        <div class="me-3">
          <span class="dot"></span>
        </div>
        <div data-test-id="tooltip-selector:multiDetails">
          +{{ multiDetails.length - 5 }}
          {{ 'general.more' | translate }}
        </div>
      </div>
    </div>
  </ng-template>
</div>
