<nz-collapse
  [nzBordered]="false"
  nzGhost
  data-test-id="wilson-collapsible-shift-assigned-user-identity:shift-assigned-user-identity-collapse"
>
  <nz-collapse-panel
    class="collapse-panel"
    [nzHeader]="'page.shifts.staff' | translate"
    [nzActive]="isExpandedOnOpen"
  >
    <wilson-shift-assigned-user-identity
      [user]="user"
      [renderReassignButton]="renderReassignButton"
      (clicked)="clicked.emit()"
    >
      <ng-content></ng-content>
    </wilson-shift-assigned-user-identity>
  </nz-collapse-panel>
</nz-collapse>
