@let hasShiftUpdatePermission = (RoleAction.Update | ablePure :
RolePermissionSubject.Shift | async); @let hasStayUpdatePermission =
(RoleAction.Update | ablePure : RolePermissionSubject.Stay | async); @let
hasNecessaryStayUpdatePermissions = (hasShiftUpdatePermission &&
hasStayUpdatePermission);
<nz-collapse
  [nzBordered]="false"
  nzGhost
  data-test-id="wilson-collapsible-shift-further-information:shift-further-information-collapse"
>
  <nz-collapse-panel
    [nzHeader]="'general.further-information' | translate"
    [nzActive]="isExpandedOnOpen"
    class="collapse-panel"
  >
    <div class="mb-2">
      <wilson-shift-further-information
        [shift]="shift"
        (reloadValidations)="reloadValidations.emit()"
      ></wilson-shift-further-information>
    </div>

    <wilson-necessary-stay-toggle
      [hasNecessaryStayUpdatePermissions]="!!hasNecessaryStayUpdatePermissions"
      [currentShiftNecessaryStay]="currentShiftNecessaryStay"
      (updatedNecessaryStay)="updatedNecessaryStay.emit($event)"
    ></wilson-necessary-stay-toggle>
  </nz-collapse-panel>
</nz-collapse>
