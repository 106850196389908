<div class="card">
  <div class="card-body p-0">
    <div class="d-flex">
      <div class="col-5 card-text text-dark p-0">
        <h2 class="tempalte-modal-header">
          {{ 'shift_template_picker.header' | translate }}
        </h2>
        <p class="template-modal-title">
          {{ 'shift_template_picker.title' | translate }}
        </p>
        <div class="search_container">
          <h2 class="search-select-title">
            {{ 'shift_template_picker.all_templates' | translate }}
          </h2>
          <input
            type="text"
            class="search-bar shadow-none rounded bg-white py-1 px-2 w-50"
            [placeholder]="'common.lbl_search_term' | translate"
            [(ngModel)]="searchFilter"
          />
        </div>
        <hr class="line-break mb-0 mt-3" />
        <div class="template-list">
          <wilson-shift-template-list-item
            *ngFor="
              let shiftTemplate of shiftTemplates
                | filterTemplateByName : searchFilter;
              let i = index
            "
            [shiftTemplate]="shiftTemplate"
            [isSelected]="i === templateIndex"
            (shiftTemplateSelected)="selectedTemplate($event, i)"
            class="cursor-pointer"
          >
          </wilson-shift-template-list-item>
        </div>
      </div>
      <div class="col-7 border-left-line">
        <wilson-shift-activities
          [activities]="shiftTemplateActivities"
          (selectionChangedEvent)="selectedActivities($event)"
        ></wilson-shift-activities>
      </div>
    </div>
    <hr class="m-0" />
    <div class="d-flex justify-content-end mt-4 button-container">
      <button
        type="button"
        class="btn btn-dark p-1 me-2 fw-normal"
        (click)="decline()"
      >
        {{ 'general.cancel' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-primary p-1 fw-normal"
        (click)="accept()"
      >
        {{ 'general.save' | translate }}
      </button>
    </div>
  </div>
</div>
