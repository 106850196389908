<div *ngIf="'timeline-payroll-notes' | featureFlagPure | async">
  <nz-collapse [nzBordered]="false" nzGhost>
    <nz-collapse-panel
      [nzHeader]="'shift_drawer.payroll_notes' | translate"
      [nzActive]="isExpandedOnOpen"
      class="collapse-panel"
    >
      <wilson-shift-notes
        [shiftId]="shiftId"
        (refresh)="refresh.emit()"
      ></wilson-shift-notes>
    </nz-collapse-panel>
  </nz-collapse>
  <nz-divider class="my-0"></nz-divider>
</div>
