<div class="text-dark" [class.fw-normal]="isDateBold" *ngIf="showDate">
  <span *ngIf="dateValue; else noData">
    {{ dateValue | date : 'dd.MM.yy, HH:mm' }}
    {{ showHourText ? ('general.hour' | translate) : '' }}
  </span>
  <ng-template #noData> --- </ng-template>
</div>
<span
  data-test-id="wilson-share-job-expired-in:status-text"
  class="list-header"
  [ngClass]="
    highlightDanger && (isExpiringInUnder12Hours || isExpired)
      ? 'text-danger'
      : 'text-dark'
  "
>
  <ng-container *ngIf="isExpired; else notExpiredYetTemplate">
    {{ 'general.expired' | translate }}
  </ng-container>
  <ng-template #notExpiredYetTemplate>
    <span [class.form-text]="isExpiryTextLight">
      <span *ngIf="dateValue && showFullText">
        {{ 'wilson.share.proposal_expires' | translate }}
      </span>
      {{ dateValue | formatDistanceFromToday }}
    </span>
  </ng-template>
</span>
