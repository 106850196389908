@let forbiddenPermissionsMessage = ('general.forbidden_action' | translate);
<div>
  <h4 class="mb-2">
    {{ 'page.general_settings.bank-title' | translate }}
  </h4>
</div>
<form [formGroup]="bankFormArray">
  <div
    *ngFor="let bankDetail of bankFormArray.controls; let i = index"
    [formGroupName]="i"
  >
    <div class="row">
      <div class="action-buttons">
        <div class="bank-account-title">
          {{ i + 1 }}. {{ 'page.general_settings.bank_details' | translate }}
        </div>
        @if(bankDetail.get('isDefault').value) {
        <div>
          <div class="default-status">
            {{ 'page.general_settings.bank_details.default' | translate }}
          </div>
        </div>
        }@else{
        <div>
          <fa-icon
            (click)="
              hasGeneralSettingsUpdatePermission && bankFormArray.valid
                ? deleteBankDetails(i, bankDetail)
                : null
            "
            [icon]="faTrash"
            nz-tooltip
            [nzTooltipTitle]="
              !hasGeneralSettingsUpdatePermission
                ? forbiddenPermissionsMessage
                : ''
            "
            [ngClass]="{
              'icon-disabled':
                !hasGeneralSettingsUpdatePermission || !bankFormArray.valid
            }"
            class="icon"
          ></fa-icon>
          <button
            nz-button
            nzType="default"
            nz-tooltip
            [nzTooltipTitle]="
              !hasGeneralSettingsUpdatePermission
                ? forbiddenPermissionsMessage
                : ''
            "
            type="button"
            [disabled]="
              !hasGeneralSettingsUpdatePermission || !bankFormArray.valid
            "
            (click)="setAsDefault(bankDetail)"
          >
            {{ 'page.general_settings.bank_details_default' | translate }}
          </button>
        </div>
        }
      </div>
      <div class="w-100">
        <div class="d-flex content">
          <div class="form-group input-field">
            <label class="form-label mb-0" for="bankName">
              {{ 'page.general_settings.bank-name' | translate }}
            </label>
            <input
              [class.is-invalid]="isFieldInvalid('bankName', bankDetail)"
              class="br-0 rounded-0"
              nz-input
              required
              [placeholder]="'general.input' | translate"
              name="bankName"
              formControlName="bankName"
              type="text"
              nz-tooltip
              [nzTooltipTitle]="
                !hasGeneralSettingsUpdatePermission
                  ? forbiddenPermissionsMessage
                  : ''
              "
              (blur)="onBankDetailsChange(bankDetail)"
            />
          </div>

          <div class="form-group input-field">
            <label class="form-label mb-0" for="iban">
              {{ 'page.general_settings.iban' | translate }}
            </label>
            <input
              [class.is-invalid]="isFieldInvalid('iban', bankDetail)"
              class="br-0 rounded-0"
              nz-input
              required
              [placeholder]="'general.input' | translate"
              name="iban"
              formControlName="iban"
              nz-tooltip
              [nzTooltipTitle]="
                !hasGeneralSettingsUpdatePermission
                  ? forbiddenPermissionsMessage
                  : ''
              "
              type="text"
              (blur)="onBankDetailsChange(bankDetail)"
            />
          </div>

          <div class="form-group input-field">
            <label class="form-label mb-0" for="bic">
              {{ 'page.general_settings.bic' | translate }}
            </label>
            <input
              [class.is-invalid]="isFieldInvalid('bic', bankDetail)"
              class="br-0 rounded-0"
              nz-input
              required
              [placeholder]="'general.input' | translate"
              name="bic"
              formControlName="bic"
              type="text"
              nz-tooltip
              [nzTooltipTitle]="
                !hasGeneralSettingsUpdatePermission
                  ? forbiddenPermissionsMessage
                  : ''
              "
              (blur)="onBankDetailsChange(bankDetail)"
            />
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="form-group input-field">
            <label class="form-label mb-0" for="accountHolderName">
              {{ 'page.general_settings.account-holder-name' | translate }}
            </label>
            <input
              class="br-0 rounded-0"
              nz-input
              [placeholder]="'general.input' | translate"
              name="accountHolderName"
              formControlName="accountHolderName"
              type="text"
              nz-tooltip
              [nzTooltipTitle]="
                !hasGeneralSettingsUpdatePermission
                  ? forbiddenPermissionsMessage
                  : ''
              "
              (blur)="onBankDetailsChange(bankDetail)"
            />
          </div>
          <div class="form-group input-field">
            <label class="form-label mb-0" for="accountNumber">
              {{ 'page.general_settings.account-number' | translate }}
            </label>
            <input
              class="br-0 rounded-0"
              nz-input
              [placeholder]="'general.input' | translate"
              name="accountNumber"
              formControlName="accountNumber"
              type="text"
              nz-tooltip
              [nzTooltipTitle]="
                !hasGeneralSettingsUpdatePermission
                  ? forbiddenPermissionsMessage
                  : ''
              "
              (blur)="onBankDetailsChange(bankDetail)"
            />
          </div>
          <div class="form-group input-field">
            <label class="form-label mb-0" for="bankCode">
              {{ 'page.general_settings.bank-code' | translate }}
            </label>
            <input
              class="br-0 rounded-0"
              nz-input
              [placeholder]="'general.input' | translate"
              name="bankCode"
              formControlName="bankCode"
              type="text"
              nz-tooltip
              [nzTooltipTitle]="
                !hasGeneralSettingsUpdatePermission
                  ? forbiddenPermissionsMessage
                  : ''
              "
              (blur)="onBankDetailsChange(bankDetail)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="btn-more">
    <button
      nz-button
      nzType="default"
      type="button"
      class="w-100"
      nz-tooltip
      [nzTooltipTitle]="
        !hasGeneralSettingsUpdatePermission ? forbiddenPermissionsMessage : ''
      "
      [disabled]="!hasGeneralSettingsUpdatePermission"
      (click)="addMoreBankDetails()"
    >
      <fa-icon [icon]="faPlus"></fa-icon>
      <span class="ms-2">
        {{ 'page.general_settings.add_more_bank_details' | translate }}
      </span>
    </button>
  </div>
</form>
