<nz-collapse
  [nzBordered]="false"
  nzGhost
  data-test-id="wilson-collapsible-shift-attachments:shift-attachments-collapse"
>
  <nz-collapse-panel
    [nzHeader]="shiftAttachmentsSectionHeader"
    [nzActive]="isExpandedOnOpen"
    class="collapse-panel"
  >
    <wilson-shift-attachments
      (updatedAttachments)="updatedAttachments($event)"
      (deleteAttachments)="deleteAttachments($event)"
      [shift]="selectedShift"
      [showTitle]="false"
      [attachments]="shiftAttachments$ | async"
      [showAllAttachments]="true"
      [showAllButtonAndDrawer]="false"
    ></wilson-shift-attachments>
  </nz-collapse-panel>
</nz-collapse>

<ng-template #shiftAttachmentsSectionHeader>
  <span>
    {{ 'shift_drawer.shift_attachments' | translate }}
  </span>
  <ng-container *ngIf="shiftAttachments$ | async as shiftAttachments">
    <span> ({{ shiftAttachments?.length }}) </span>
  </ng-container>
</ng-template>

<nz-divider class="my-0"></nz-divider>
