import { Base } from '@wilson/base';
import { WilsonApp } from '../wilson-app';
import { User } from '../accounts/User';
import {
  Activity,
  ResolvedActivity,
  ResolvedActivityWithReports,
} from '../activities/activity';
import { ActivityReport } from '../activities/activity-report';
import { GeoLocation } from '../locations/geoLocation';
import { OrganizationalUnit } from '../organizational-units/organizationalUnit';
import { Sector } from '../sectors/sector';
import { ShiftActivityStatus, PublicationStatus } from './publicationStatus';
import { ShiftScore } from './shift-score';
import { ShiftState } from './shift-state';
import { ShiftSeriesShift } from './shiftSeries';
import { OperationStatus } from '../activities/operationStatus';
import { Label } from '../labels/labels';
import { ShiftReport } from './shift-report';
import { PayrollTransaction } from '../payroll/payroll-enums';
import { PayrollSettingCategory } from '../payroll/payroll-setting';

export interface Shift extends Base, Partial<WithPreparedAttributes> {
  activities?: Activity[] | null;
  comment: string | null;
  createdFrom?: WilsonApp | null;
  deviation?: number;
  externalId?: string | null;
  name: string;
  organizationalUnit?: OrganizationalUnit | null;
  organizationalUnitId: string;
  payrollInformation?: ShiftPayrollInformation | null;
  publicationStatus: PublicationStatus;
  shiftCategory?: ShiftCategory | null;
  shiftCategoryId: string;
  shiftSeries?: ShiftSeriesShift | null;
  startDate: string;
  startDatetime?: string;
  endDatetime?: string;
  state: ShiftState;
  user?: User | null;
  userId: string | null;
  shiftScores?: ShiftScore[];
  shiftReport?: ShiftReport | null;
  excludeInValidation?: boolean;
  priority?: number | null;
  labels?: Label[] | null;
  projectId?: string | null;
  lastSeenAt?: string | null;
  confirmedAt?: string | null;
  declinedAt?: string | null;
  declineReason?: string | null;
}

export interface ShiftWithAzmAttributes extends Shift {
  agreementIds: string[];
  allActivitiesHaveAgreement: boolean;
  payrollTransactions: (PayrollTransaction & {
    payrollSettingCategory: PayrollSettingCategory | null;
  })[];
  plannedDuration: number;
  plannedReportedDeviationDuration: number;
  reportedDuration: number;
  shiftNotes: ShiftNotes[];
}

export interface WithPreparedAttributes {
  startDatetime: string;
  endDatetime: string;
  deviatedStartDatetime: string | null;
  deviatedEndDatetime: string | null;
  reportedStartDatetime: string | null;
  reportedEndDatetime: string | null;
  startLocationId: string;
  endLocationId: string;
  startLocation: {
    locationCode: string;
    name: string;
  };
  endLocation: {
    locationCode: string;
    name: string;
  };
  reportedStartLocationId: string | null | undefined;
  reportedEndLocationId: string | null | undefined;
  reportedStartLocation:
    | {
        locationCode: string;
        name: string;
      }
    | null
    | undefined;
  reportedEndLocation:
    | {
        locationCode: string;
        name: string;
      }
    | null
    | undefined;
  operationalStatus: OperationStatus;
  priority: number | null;
  projectId: string | null;
  ignoreStayNecessary: boolean;
}

export interface ResolvedShift extends Shift {
  activities: Activity[];
  user: User;
  organizationalUnit: OrganizationalUnit;
}

export interface ResolvedShiftWithReports extends Shift {
  activities: ResolvedActivityWithReports[];
  user: User;
  organizationalUnit: OrganizationalUnit;
}

export interface ResolvedShiftWithActivity extends Shift {
  activities: ResolvedActivity[];
  user: User;
  organizationalUnit: OrganizationalUnit;
}

export interface ShiftWithActivityStatus extends ResolvedShiftWithActivity {
  activityStatus: ShiftActivityStatus;
}

export interface ShiftCategory extends Base {
  name: string;
  sectorIds: string[] | null;
  sectors?: Sector[];
}

export type ShiftWithActivitiesWithLocations = Shift & {
  activities: (Activity & {
    startLocation: GeoLocation;
    endLocation: GeoLocation;
    activityReports: ActivityReport[];
  })[];
};

export enum ValidationCheckCategory {
  Qualifications = 'qualification',
  WorkingTimeRules = 'working-time-rule',
  ShiftLogic = 'shift-logic',
}

export enum ShiftCategoryNames {
  Disposition = 'shift_category.disposition',
  NonDepatureShift = 'shift_category.non-depature-shift',
  Normal = 'shift_category.normal',
  Office = 'shift_category.office',
  OnCall = 'shift_category.on-call',
  SickShift = 'shift_category.sick-shift',
  Training = 'shift_category.training',
  HomeTransfer = 'shift_category.home-transfer',
}

export enum ShiftCategoryEnum {
  Disposition = 'disposition',
  NonDepatureShift = 'non-depature-shift',
  Normal = 'normal',
  Office = 'office',
  OnCall = 'on-call',
  SickShift = 'sick-shift',
  Training = 'training',
  HomeTransfer = 'home-transfer',
}

export enum ShiftCategoriesIdsMap {
  Disposition = 'bc38ab77-04f2-4db4-92c4-bc4f0b18aeec',
  NonDepatureShift = 'd9d55e2c-2ddc-4a77-89b4-95676045d828',
  Normal = 'e9863d0b-1af1-48f0-be13-7b6245b7e638',
  Office = 'd5b7ac96-74b7-495f-8766-a559d89acc25',
  OnCall = '214a0f6b-dbbe-4da6-9a6f-d646281cd05f',
  SickShift = '74581926-2b1a-41f6-9bae-204716fc31a1',
  Training = '93857c9a-899a-4020-ad08-f234d6f6615c',
  HomeTransfer = 'bdf6bcf2-c6f9-4e29-baeb-b3d7122704f3',
}

export const ShiftCategoriesIdToNameMap: Record<string, ShiftCategoryNames> = {
  'e9863d0b-1af1-48f0-be13-7b6245b7e638': ShiftCategoryNames.Normal,
  'bc38ab77-04f2-4db4-92c4-bc4f0b18aeec': ShiftCategoryNames.Disposition,
  '93857c9a-899a-4020-ad08-f234d6f6615c': ShiftCategoryNames.Training,
  'd5b7ac96-74b7-495f-8766-a559d89acc25': ShiftCategoryNames.Office,
  '214a0f6b-dbbe-4da6-9a6f-d646281cd05f': ShiftCategoryNames.OnCall,
  '74581926-2b1a-41f6-9bae-204716fc31a1': ShiftCategoryNames.SickShift,
  'd9d55e2c-2ddc-4a77-89b4-95676045d828': ShiftCategoryNames.NonDepatureShift,
  'bdf6bcf2-c6f9-4e29-baeb-b3d7122704f3': ShiftCategoryNames.HomeTransfer,
};

export const ShiftCategoriesColorCode: Record<string, string> = {
  'e9863d0b-1af1-48f0-be13-7b6245b7e638': 'var(--grey-6)',
  'bc38ab77-04f2-4db4-92c4-bc4f0b18aeec': 'var(--gold-5)',
  '93857c9a-899a-4020-ad08-f234d6f6615c': 'var(--magenta-4)',
  'd5b7ac96-74b7-495f-8766-a559d89acc25': 'var(--blue-5)',
  '214a0f6b-dbbe-4da6-9a6f-d646281cd05f': 'var(--lime-5)',
  '74581926-2b1a-41f6-9bae-204716fc31a1': 'var(--red-4)',
  'd9d55e2c-2ddc-4a77-89b4-95676045d828': 'var(--orange-5)',
  'bdf6bcf2-c6f9-4e29-baeb-b3d7122704f3': 'var(--cyan-5)',
};

export const ShiftCardBackgroundColor: Record<string, string> = {
  'e9863d0b-1af1-48f0-be13-7b6245b7e638': '#bfbfbf33',
  'bc38ab77-04f2-4db4-92c4-bc4f0b18aeec': '#FFD66633',
  '93857c9a-899a-4020-ad08-f234d6f6615c': '#FF85C033',
  'd5b7ac96-74b7-495f-8766-a559d89acc25': '#40A9FF33',
  '214a0f6b-dbbe-4da6-9a6f-d646281cd05f': '#bae63733',
  '74581926-2b1a-41f6-9bae-204716fc31a1': '#ff787533',
  'd9d55e2c-2ddc-4a77-89b4-95676045d828': '#ffa94033',
  'bdf6bcf2-c6f9-4e29-baeb-b3d7122704f3': '#36cfc933',
};

export interface ShiftValidationDetails {
  category: ValidationCheckCategory;
  checkName: string;
  valid: boolean;
  errorCausingObjectIds: number[];
}

export interface DeviationRange {
  from: number;
  to: number;
}

export interface ShiftInDateRangeDto {
  startDate: string;
  endDate: string;
  organizationalUnitId?: string[];
  userId?: string | string[];
}

export interface ShiftPayrollInformation extends Base {
  travelKM: number;
  spontaneousShiftBonus: boolean;
  shiftId: string;
  traineeOnBoard: boolean;
  coveredFoodExpenses: CoveredFoodExpenseEnum[];
  shift?: Shift;
}

export interface ShiftNotes {
  id: string;
  createdByUserId: string;
  note: string;
  shiftId: string;
  createdAt: string;
}

export const ALL_SHIFT_RELATIONS = [
  `activities.activityCategory`,
  `activities.activityReports.location`,
  `activities.activityReports`,
  `activities.activityComments`,
  `activities.activityComments.sender`,
  `activities.job`,
  `activities.job.organizationalUnit`,
  `activities.endLocation`,
  `activities.service`,
  `activities.startLocation`,
  `activities`,
  `organizationalUnit`,
  `payrollInformation`,
  `shiftCategory`,
  `shiftSeries`,
  `user`,
  'shiftScores',
];

export const PREPARED_SHIFT = 'preparedShift';
export const PREPARED_SHIFT_SERIES = 'preparedShiftSeries';
export const PREPARED_SHIFT_VIA_AI = 'preparedShiftViaAi';

export enum CoveredFoodExpenseEnum {
  Breakfast = 'breakfast',
  Lunch = 'lunch',
  Dinner = 'dinner',
}
