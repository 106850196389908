<button
  [disabled]="disabled"
  class="btn btn-ligth via-btn border border-dark text-dark fw-normal"
  [ngClass]="viaPointIds?.length ? 'btn-dark text-white' : ''"
  type="button"
  (click)="openViaPointsPopup()"
>
  {{ viaPointIds?.length || '' }}
  {{ 'page.service.via_points' | translate }}
</button>
