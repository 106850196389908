import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { ColumnState } from 'ag-grid-community';
import { SetColumnSetting } from './columns.action';

interface AgGridColumnSettingsModel {
  settings: Record<string, Record<string, ColumnState[]>>;
}

const defaults: AgGridColumnSettingsModel = {
  settings: {},
};

@State<AgGridColumnSettingsModel>({
  name: 'AgGridColumnSettings',
  defaults,
})
@Injectable()
export class AgGridColumnSettingsState {
  static columnStates({
    userId,
    gridId,
  }: {
    userId: string;
    gridId: string;
  }): (state: AgGridColumnSettingsModel) => ColumnState[] {
    return createSelector(
      [AgGridColumnSettingsState],
      (state: AgGridColumnSettingsModel) => {
        return state.settings[userId]?.[gridId];
      },
    );
  }

  @Action(SetColumnSetting)
  setColumnSetting(
    ctx: StateContext<AgGridColumnSettingsModel>,
    { payload }: SetColumnSetting,
  ): void {
    ctx.patchState({
      settings: {
        ...ctx.getState().settings,
        [payload.userId]: {
          ...ctx.getState().settings[payload.userId],
          [payload.gridId]: payload.columnStates,
        },
      },
    });
  }
}
