@let hasShiftUpdatePermission = !!(RoleAction.Update | ablePure :
RolePermissionSubject.Shift | async); @let forbiddenMessage =
('general.forbidden_action' | translate);

<div class="pt-2">
  <div class="row">
    <div class="col-9">
      {{ 'page.shifts.short_term_shift_takeover' | translate }}
    </div>
    <div class="col-3 toggle-container" data-test-id="wilson-shift-wage-data:">
      <nz-switch
        nz-tooltip
        [nzTooltipTitle]="!hasShiftUpdatePermission ? forbiddenMessage : ''"
        [nzDisabled]="!hasShiftUpdatePermission || areInputsDisabled"
        [(ngModel)]="hasShiftBonus"
        [nzCheckedChildren]="checkedTemplate"
        (ngModelChange)="changeSpontaneousShiftBonus($event)"
        class="d-flex justify-content-end"
        data-test-id="wilson-shift-wage-data:short-term-shift-takeover"
      ></nz-switch>
    </div>
  </div>

  <ng-container
    *ngIf="'mobile-shift-payroll-information' | featureFlagPure | async"
  >
    <br />
    <div class="row">
      <div class="col-9">
        {{ 'page.shifts.trainee_on_board' | translate }}
      </div>
      <div class="col-3 toggle-container">
        <nz-switch
          nz-tooltip
          [nzTooltipTitle]="!hasShiftUpdatePermission ? forbiddenMessage : ''"
          [nzDisabled]="!hasShiftUpdatePermission || areInputsDisabled"
          [(ngModel)]="traineeOnBoard"
          [nzCheckedChildren]="checkedTemplate"
          (ngModelChange)="changeTraineeOnBoard($event)"
          class="d-flex justify-content-end"
          data-test-id="wilson-shift-wage-data:trainee-on-board"
        ></nz-switch>
      </div>
    </div>

    <br />
    <div class="row mb-3">
      <div class="col-12">
        <nz-select
          nzShowSearch
          nzAllowClear
          [nzShowArrow]="true"
          [(ngModel)]="coveredFoodExpenses"
          (ngModelChange)="setCoveredFoodExpenses($event)"
          nzMode="multiple"
          [nzPlaceHolder]="'page.shifts.covered_food_expenses' | translate"
          [nzDisabled]="!hasShiftUpdatePermission || areInputsDisabled"
          nz-tooltip
          [nzTooltipTitle]="!hasShiftUpdatePermission ? forbiddenMessage : ''"
          data-test-id="wilson-shift-wage-data:covered-food-expenses"
        >
          <nz-option
            [nzLabel]="
              'page.shifts.covered_food_expenses.' +
                CoveredFoodExpenseEnum.Breakfast | translate
            "
            [nzValue]="CoveredFoodExpenseEnum.Breakfast"
          ></nz-option>
          <nz-option
            [nzLabel]="
              'page.shifts.covered_food_expenses.' +
                CoveredFoodExpenseEnum.Lunch | translate
            "
            [nzValue]="CoveredFoodExpenseEnum.Lunch"
          ></nz-option>
          <nz-option
            [nzLabel]="
              'page.shifts.covered_food_expenses.' +
                CoveredFoodExpenseEnum.Dinner | translate
            "
            [nzValue]="CoveredFoodExpenseEnum.Dinner"
          ></nz-option>
        </nz-select>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #checkedTemplate>
  <i class="fa fa-check icon-mar-top"></i>
</ng-template>
