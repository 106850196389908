<div #tooltip class="tooltip-container">
  <div class="fw-normal text-primary header">
    <i class="fas fa-info-circle me-2"></i> {{ header }}
  </div>
  <div class="d-flex row">
    <div class="me-3 col-4">
      <div class="mb-1 fw-normal">
        {{ 'tooltip.subheader.qualifications_and_professions' | translate }}
      </div>
      <ng-container
        *ngIf="
          qualificationNames?.length > 0 || professionValidations?.length > 0;
          else emptyStateQualification
        "
      >
        <div class="d-flex flex-column">
          <div class="d-flex" *ngFor="let item of qualificationNames">
            <div class="me-1">
              <span
                [ngClass]="
                  item.valid
                    ? 'fas fa-check-circle text-primary'
                    : 'fas fa-times-circle text-danger'
                "
              ></span>
            </div>
            {{ item.qualificationType + ' - ' + item.title }}
          </div>
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex" *ngFor="let item of professionValidations">
            <div class="me-1">
              <span
                [ngClass]="
                  item.valid
                    ? 'fas fa-check-circle text-primary'
                    : 'fas fa-times-circle text-danger'
                "
              ></span>
            </div>
            {{ 'enum.shift_validation.' + item.checkName | translate }}
          </div>
        </div>
      </ng-container>

      <ng-template #emptyStateQualification>
        {{
          'tooltip.subheader.no_qualifications_or_professions_deposited'
            | translate
        }}
      </ng-template>
    </div>
    <div class="col">
      <div class="mb-1 fw-normal">
        {{ 'tooltip.subheader.working_regulations' | translate }}
      </div>
      <ng-container
        *ngIf="shiftValidationDetails?.length > 0; else emptyStateNoWorkingTime"
      >
        <div class="d-flex" *ngFor="let item of shiftValidationDetails">
          <ng-container
            *ngIf="
              item.checkName === 'user-approval-check';
              else otherQualifications
            "
          >
            <div class="me-1">
              <span
                *ngIf="!item.valid"
                class="fas fa-times-circle text-danger"
              ></span>
              <span
                *ngIf="item.valid"
                class="fas fa-check-circle text-primary"
              ></span>
            </div>
            <ng-container *ngIf="!item.valid; else valid">
              {{
                'enum.shift_validation.' + item.checkName + '.' + item.valid
                  | translate
              }}
            </ng-container>
            <ng-template #valid>
              {{
                'enum.shift_validation.' + item.checkName + '.' + item.valid
                  | translate
              }}
            </ng-template>
          </ng-container>
          <ng-template #otherQualifications>
            <div class="me-1">
              <span
                *ngIf="!item.valid"
                class="fas fa-times-circle text-danger"
              ></span>
              <span
                *ngIf="item.valid"
                class="fas fa-check-circle text-primary"
              ></span>
            </div>
            {{ 'enum.shift_validation.' + item.checkName | translate }}
          </ng-template>
        </div>
      </ng-container>
      <ng-template #emptyStateNoWorkingTime>
        {{
          'tooltip.subheader.no_working_time_regulations_deposited' | translate
        }}
      </ng-template>
    </div>
  </div>
</div>
