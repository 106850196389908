import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({ providedIn: 'root' })
export class TimelineHelperService {
  protected translate = inject(TranslateService);
  protected toastMessage = inject(NzMessageService);

  informUserHasNoPermissionsToMoveEntity(): void {
    this.toastMessage.info(this.translate.instant('general.forbidden_action'));
  }
}
