import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { AffectedStop } from '@wilson/service-deviation/interfaces';
import {
  ActivityFilter,
  FeatureName,
  GeoLocation,
  Job,
  OperationStatus,
  ResolvedActivity,
  ResolvedActivityReport,
  RoleAction,
  RolePermissionSubject,
  Shift,
} from '@wilson/interfaces';
import { LocationsService } from '@wilson/locations';
import { WilsonApp } from '@wilson/interfaces';
import { Observable } from 'rxjs';
import { ReportUtilityService } from '../../../modules/report-utilities/services/report-utility.service';
@Component({
  selector: 'wilson-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityComponent implements OnInit {
  @Input() activity: ResolvedActivity;
  @Input() selectedUserTab: ActivityFilter;
  @Input() shift: Shift;
  @Input() isSeries = false;
  @Input() showWilsonShareIcon = false;
  @Input() startDateTimeOverlap: string;
  @Input() endDateTimeOverlap: string;

  public operationalStatusBgColor: string;
  public reportedStartTime: string | null;
  public reportedEndTime: string | null;
  public reportedStartLocation: string | null;
  public reportedEndLocation: string | null;
  public viaLocations$: Observable<GeoLocation[]>;
  public startGeoLocation: GeoLocation;
  public endGeoLocation: GeoLocation;
  public WilsonApp = WilsonApp;
  public isAbleToEditQualifications = false;
  readonly AffectedStop = AffectedStop;
  readonly ActivityFilter = ActivityFilter;

  get isShared(): string | Job {
    return this.activity.job || this.activity.jobId;
  }
  FeatureName = FeatureName;
  RoleAction = RoleAction;
  RolePermissionSubject = RolePermissionSubject;

  constructor(
    private readonly reportUtilityService: ReportUtilityService,
    private readonly locationsService: LocationsService,
    private readonly cdRef: ChangeDetectorRef,
  ) {}

  public async ngOnInit(): Promise<void> {
    if (this.activity) {
      this.setPlannedLocations();
      this.setReportedTimeAndLocations(this.activity.activityReports);
      this.setOperationalStatusBadgeTextAndBackgrondColor();
      this.viaLocations$ = this.locationsService.getGeoLocationsByIds(
        this.activity.viaLocationIds as string[],
      );
      this.cdRef.detectChanges();
    }
  }

  private setPlannedLocations(): void {
    this.startGeoLocation = this.activity?.startLocation;
    this.endGeoLocation = this.activity?.endLocation;
  }

  private setReportedTimeAndLocations(
    activityReports: ResolvedActivityReport[],
  ): void {
    const startReport =
      this.reportUtilityService.latestStartReport(activityReports);
    const endReport =
      this.reportUtilityService.latestEndReport(activityReports);
    this.reportedStartTime = startReport ? startReport.dateTime : null;
    this.reportedEndTime = endReport ? endReport.dateTime : null;
    this.reportedStartLocation = startReport?.location
      ? startReport.location.locationCode
        ? `${startReport.location.locationCode} - ${startReport.location.name}`
        : startReport.location.name
      : null;
    this.reportedEndLocation = endReport?.location
      ? endReport.location.locationCode
        ? `${endReport.location.locationCode} - ${endReport.location.name}`
        : endReport.location.name
      : null;
  }

  private setOperationalStatusBadgeTextAndBackgrondColor(): void {
    const status = this.activity?.operationalStatus;
    if (status === OperationStatus.Completed) {
      this.operationalStatusBgColor = 'badge-secondary-dark';
    } else if (status === OperationStatus.NotStarted) {
      this.operationalStatusBgColor = 'badge-primary';
    } else if (status === OperationStatus.Ongoing) {
      this.operationalStatusBgColor = 'badge-primary-dark';
    } else if (status === OperationStatus.Paused) {
      this.operationalStatusBgColor = 'badge-warning';
    } else if (
      status === OperationStatus.SkippedByUser ||
      status === OperationStatus.Cancelled
    ) {
      this.operationalStatusBgColor = 'badge-warning';
    }
  }
}
