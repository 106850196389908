<nz-badge
  [nzCount]="viaLocations?.length"
  [nzOverflowCount]="9"
  [nzStyle]="{
    'background-color': 'var(--grey-8)'
  }"
>
  <span
    class="via-icon fw-normal my-auto default-icon"
    nz-tooltip
    [nzTooltipTitle]="
      viaLocations?.length && !disableTooltip ? viaLocationsTooltip : ''
    "
    [nzTooltipColor]="'#fff'"
    [nzTooltipOverlayStyle]="{
      'max-width': '500px'
    }"
  >
    {{ 'page.services.via' | translate }}
  </span>
</nz-badge>

<ng-template #viaLocationsTooltip>
  <wilson-via-points-list
    [startLocation]="startLocation"
    [endLocation]="endLocation"
    [viaLocations]="viaLocations"
  ></wilson-via-points-list>
</ng-template>
