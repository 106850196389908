<div class="assign-activity-container">
  <div
    class="d-flex flex-column justify-content-between assign-activity-header"
  >
    <div class="d-flex mb-2">
      <img src="../../../../assets/img/svg-icons/generic-person.svg" />
      <div class="ml-3 fw-normal text-dark">
        {{ 'page.qualifications.add_qualifications' | translate }}
      </div>
    </div>
    <div class="mt-0 mb-3">
      <b>{{ 'page.qualifications.section' | translate }}:</b>
      {{ startLocation?.name ? startLocation.name : '---' }}
      - {{ endLocation?.name ? endLocation.name : '---' }}
    </div>
    <div
      *ngIf="{
        availableQualifiables: availableQualifiables$ | async
      } as data"
      class="mt-0 mb-3"
    >
      <wilson-loading-spinner
        *ngIf="isLoading; else qualificationSelect"
      ></wilson-loading-spinner>
      <ng-template #qualificationSelect>
        <nz-select
          nzMode="multiple"
          [nzPlaceHolder]="'general.search_or_select' | translate"
          nzAllowClear
          [nzShowArrow]="true"
          [ngModel]="selectedQualifications"
          (ngModelChange)="
            setSelectedQualifiables($event, data.availableQualifiables)
          "
        >
          <nz-option
            *ngFor="let item of data.availableQualifiables"
            [nzLabel]="
              item.qualificationCategoryDisplayName +
              ' - ' +
              item.qualificationDisplayName
            "
            [nzValue]="item.qualification.id"
          ></nz-option>
        </nz-select>
      </ng-template>
    </div>
  </div>
  <div class="assign-activity-footer">
    <div class="d-flex justify-content-between mt-2">
      <button
        type="button"
        class="btn btn-dark p-1 me-2 fw-normal"
        (click)="cancel()"
      >
        {{ btnCancelText | translate }}
      </button>
      <button
        [disabled]="submitting"
        type="button"
        class="btn btn-primary p-1 fw-normal"
        (click)="saveQualification()"
      >
        {{ (submitting ? 'general.saving' : btnOkText) | translate }}
      </button>
    </div>
  </div>
</div>
