@let forbiddenPermissionsMessage = ('general.forbidden_action' | translate);
<div
  *ngIf="{
    isItemInBucket: activity | isItemInBucket | async,
    canBeShared: activity | anyActivityCanBeAddedToShare
  } as data"
  nz-tooltip
  [nzTooltipTitle]="
    isIconDisabled
      ? hasUpdatePermissions
        ? ''
        : forbiddenPermissionsMessage
      : (tooltipText ??
          (data.isItemInBucket
            ? 'wilson.share.remove_from_share_request'
            : !data.canBeShared
            ? 'wilson.share.agreement_exist'
            : 'wilson.share.add_to_share_request') | translate)
  "
  [class.cursor-not-allowed]="isIconDisabled"
>
  <div
    class="cursor-pointer text-dark position-relative"
    (click)="
      data.isItemInBucket
        ? removeActivityFromBucket(activity.id)
        : addActivityToBucket(activity)
    "
    [class.disable-share]="isIconDisabled"
  >
    <i class="fas fa-handshake me-1 my-auto"></i>
    <i
      class="fas action-icon"
      [ngClass]="data.isItemInBucket ? 'fa-minus' : 'fa-plus'"
    ></i>
  </div>
</div>
