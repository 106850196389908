@let hasShiftReadPermission = RoleAction.Read | ablePure :
RolePermissionSubject.Shift | async; @if(hasShiftReadPermission){

<nz-collapse [nzBordered]="false" nzGhost>
  <nz-collapse-panel
    [nzHeader]="'shift_drawer.wage_data' | translate"
    [nzActive]="isExpandedOnOpen"
    class="collapse-panel"
  >
    <wilson-shift-wage-data
      class="mt-2"
      [shift]="shift"
      [areInputsDisabled]="areInputsDisabled"
    ></wilson-shift-wage-data>
  </nz-collapse-panel>
</nz-collapse>
}
