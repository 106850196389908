<form [formGroup]="form">
  <div class="row d-flex align-items-center">
    <label class="col-5 col-form-label fw-normal"
      >{{ 'account.name' | translate }}*</label
    >
    <div class="col pe-1">
      <input
        type="text"
        class="form-control"
        [class.is-invalid]="form?.controls['name'] | formControlInvalid"
        formControlName="name"
        [placeholder]="'account.name' | translate"
      />
    </div>
    <div class="col-1 pe-0 ps-1">
      <div
        class="color-picker-circle"
        [style.background]="form?.controls['colorCode'].value"
        [cpPosition]="'auto'"
        [cpAlphaChannel]="'disabled'"
        [cpOutputFormat]="'hax'"
        [colorPicker]="form?.controls['colorCode'].value"
        (colorPickerChange)="form?.controls['colorCode'].patchValue($event)"
        [cpPresetColors]="presetColors"
      ></div>
    </div>
  </div>
  <div
    class="row mb-3"
    *ngIf="form?.controls['name'].hasError('shiftTemplateNameAlreadyExists')"
  >
    <div class="col-5"></div>
    <small class="col text-warning">{{
      'page.shift_template.name_already_exists' | translate
    }}</small>
    <div class="col-1"></div>
  </div>
  <div class="mb-3 row d-flex align-items-center">
    <label class="col-5 col-form-label fw-normal"
      >{{ 'account.org_unit' | translate }}*</label
    >
    <div class="col">
      <nz-form-item class="mb-0">
        <nz-form-control
          [nzValidateStatus]="
            form?.touched && !form?.controls['organizationalUnitId'].value
              ? 'error'
              : 'success'
          "
        >
          <nz-select
            formControlName="organizationalUnit"
            [nzPlaceHolder]="'account.org_unit' | translate"
            [nzMaxTagCount]="1"
            nzShowSearch
            nzAllowClear
            [nzShowArrow]="true"
            [compareWith]="compareOrgUnitFn"
            (ngModelChange)="setOrganizationalUnit($event)"
          >
            <nz-option
              *ngFor="let organizationalUnit of organizationalUnits$ | async"
              [nzValue]="organizationalUnit"
              [nzLabel]="organizationalUnit.name"
            >
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div class="mb-3 row d-flex">
    <div class="col-5">
      <label class="col-form-label fw-normal pt-1">{{
        'general.description' | translate
      }}</label>
    </div>
    <div class="col">
      <textarea
        rows="5"
        class="form-control"
        formControlName="description"
        [placeholder]="'general.description' | translate"
      ></textarea>
    </div>
  </div>
</form>
